import Footer from '@/components/Footer'
import Image, { StaticImageData } from 'next/image'
import Link from '@/components/Link'
import betterplaceLogo from '@/assets/betterplaceLogo.svg'
import styles from './ErrorPageLayout.module.css'
import { LayoutCenter } from '@betterplace/design-system/server'
import { useTranslations } from 'next-intl'

const ErrorPageLayout = ({ children }: { children: React.ReactNode }) => {
  const t = useTranslations('nextjs.core')
  return (
    <>
      <header className={styles.container}>
        <LayoutCenter>
          <Link href={process.env.NEXT_PUBLIC_BETTERPLACE_URL}>
            <Image
              src={betterplaceLogo as StaticImageData}
              className={styles.logo}
              alt={t('betterplace_logo_alt_text')}
            />
          </Link>
        </LayoutCenter>
      </header>
      <LayoutCenter as="main" className={styles.main}>
        {children}
      </LayoutCenter>
      <Footer />
    </>
  )
}

export default ErrorPageLayout
